<template>
	<div class="page-quest">
		<div class="app-logo">
			<img class="logo" src="../../assets/images/logo-white.png">
		</div>
		<div class="app-progress">
			<div class="progress-item">
				1
			</div>
			<div class="progress-line"></div>
			<div class="progress-item blue">
				2
			</div>
		</div>
		<!-- 购买渠道 -->
		<div class="app-form" v-if="langInfo">
			<div class="form-item">
				<div class="item-title">
					<img class="icon" src="../../assets/images/icons/shop.png">
					<div class="text">
						{{ langInfo.newLangInfo.questPage.title }}
					</div>
				</div>
				<div class="form-list" v-if="showDataInfo&&showDataInfo.urlList&&showDataInfo.urlList.length">
					<app-checkbox v-for="(item,index) in showDataInfo.urlList" :key="index"
						:text="item.name==='Offline'?langInfo.newLangInfo.questPage.offline:item.name=='TikTok'?'TikTok Shop':item.name=='Other'?langInfo.newLangInfo.questPage.other:item.name"
						:checked="index===orderPay" @click.native="handClick(index)" />
				</div>
				<div class="item-tips" v-if="platformTips">
					{{platformTips}}
				</div>
			</div>
			<!-- 上传截图 -->
			<div class="form-item"
				v-if="showDataInfo.urlList.length===6&&(orderPay===4||orderPay===5)||showDataInfo.urlList.length===5&&(orderPay===3||orderPay===4)">
				<div class="item-title">
					<img class="icon" style="width: 18px;height: 20px;" src="../../assets/images/icons/book.png">
					<div class="text">
						{{ langInfo.newLangInfo.questPage.upload }}
					</div>
				</div>
				<van-uploader v-model="fileList" class="item-upload" :max-size="5000 * 1024" :after-read="afterRead">
					<div class="upload-style">
						<img class="icon" src="../../assets/images/icons/upload.png">
						<div class="u-text">
							{{ langInfo.newLangInfo.questPage.upload }}
						</div>
					</div>
				</van-uploader>
				<div class="item-tips" v-if="fileTips">
					{{fileTips}}
				</div>
			</div>
			<div class="form-item" v-else>
				<div class="item-title">
					<img class="icon" style="width: 18px;height: 20px;" src="../../assets/images/icons/book.png">
					<div class="text">
						{{ langInfo.newLangInfo.questPage.orderid }}
					</div>
				</div>
				<div class="item-value">
					<input v-model="formData.OrderID" class="value-input"
						:placeholder="langInfo.newLangInfo.registerPage.mandatory" @focus="orderTips = ''">
				</div>
				<div class="item-tips" v-if="orderTips">
					{{orderTips}}
				</div>
			</div>
		</div>
		<div class="form-buttons">
			<!-- 上一步 -->
			<div class="button-border" @click="$router.back()">
				{{ langInfo.newLangInfo.registerPage.last }}
			</div>
			<div class="button-blue" @click="handSubmit">
				{{ langInfo.newLangInfo.registerPage.submit }}
			</div>
		</div>
		<app-loading :show="uploadLoading" />
	</div>
</template>

<script>
	import {
		List
	} from '../../util/en_config_new';
	import {
		langData
	} from '../../util/lang_config_new';
	import {
		Uploader,
		Toast
	} from 'vant';
	import appCheckbox from '../../components/app-checkbox/app-checkbox.vue';
	import appLoading from '../../components/app-loading/app-loading.vue';
	export default {
		components: {
			appCheckbox,
			appLoading,
			[Uploader.name]: Uploader
		},
		data() {
			return {
				langInfo: null,
				showDataInfo: null, //包含手机号校验正则
				orderPay: -1,
				fileList: [],
				formData: {
					User_Platform: '', //平台
					OrderID: '', //订单id 
					File_Name: ''
				},
				platformTips: '',
				fileTips: '',
				orderTips: '',
				uploadLoading:false,
			};
		},
		created() {
			this.init();
		},
		methods: {
			init() {
				if (localStorage.getItem('NationName')) {
					this.formData.Nation = localStorage.getItem('NationName');
					this.langInfo = this.setLang();
				} else {
					this.$router.replace('/index')
				}
			},
			afterRead(file) {
				this.fileTips = '';
				// 上传图片到图片服务器
				this.uploadLoading = true;
				let fd = new FormData();
				fd.append('file', file.file);
				this.axios.post(`/api/upload/file`, fd, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					}).then((res) => {
						this.uploadLoading = false;
						if (res.data.code == 200) {
							this.formData.File_Name = res.data.filename;
						} else {
							this.formData.File_Name = '';
							this.fileTips = res.data.msg;
						}
					})
					.catch((err) => {
						this.uploading = false
					});
			},
			handSubmit() {
				/**
				 * 平台
				 * */
				if (!this.formData.User_Platform) {
					this.platformTips = this.langInfo.newLangInfo.registerPage.mandatory;
					return;
				}

				if (this.showDataInfo.urlList.length === 6 && (this.orderPay === 4 || this.orderPay === 5) || this
					.showDataInfo.urlList.length === 5 && (this.orderPay === 3 || this.orderPay === 4)) { //判断是否上传文件
					if (!this.formData.File_Name) {
						this.fileTips = this.langInfo.newLangInfo.questPage.upload;
						return false;
					}
					delete this.formData.OrderID;
				} else { //判断是否 填写订单id
					if (!this.formData.OrderID) {
						this.orderTips = this.langInfo.newLangInfo.tipsInfo.order_error;
						return false;
					}
					delete this.formData.File_Name;
				}

				let params = Object.assign({}, JSON.parse(localStorage.getItem('RegisterInfo')), this.formData)
				this.axios.post(`/api/info/`, params).then((res) => {
						let resstr = res.data;
						if (resstr.code === 200) {
							this.handleLogin(params.Email);
						} else {
							Toast(resstr.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			handleLogin(email) {
				this.axios.get(`/api/search/`, {
						params: {
							Email: email
						}
					}).then((res) => {
						let resstr = res.data;
						if (parseInt(resstr.code) === 200) {
							if (resstr.msg[0].User_id) {
								Toast.loading({
									duration: 1000,
									message: 'sign in...',
									forbidClick: true,
									loadingType: 'spinner',
								});
								localStorage.setItem('LoginInfo', JSON.stringify(resstr.msg[0]));
								localStorage.setItem('LoginToken', resstr.access_token);
								localStorage.removeItem('RegisterInfo');
								document.cookie = `_at=${resstr.access_token};path=/`;
								this.$router.replace('/info');
							} else {
								this.$router.replace('/register');
							}
						} else {
							// eslint-disable-next-line
							Toast(resstr.msg);
						}
					})
					.catch((err) => {
						// eslint-disable-next-line
						console.log(err);
					});
			},
			handClick(index) {
				this.orderPay = index;
				this.formData.User_Platform = this.showDataInfo.urlList[index].name === 'Offline' ? 'Offline channels' :
					this.showDataInfo.urlList[index].name;
				this.platformTips = '';
			},
			setLang() {
				let Info = langData.filter((res) => res.Nation == this.formData.Nation)[0];
				this.showDataInfo = List.filter((res) => res.Nation == this.formData.Nation)[0];
				return Info == undefined ? langData[0] : Info;
			}
		}
	};
</script>

<style lang="less">
	.page-quest {
		width: 100vw;
		height: 100vh;
		background: #4E5E9A;
		position: relative;

		.app-logo {
			padding: 54px 88px 54px;
			display: flex;
			justify-content: center;

			.logo {
				width: 200px;
				height: 60px;
			}
		}

		.app-progress {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 44px;

			.progress-item {
				width: 32px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				border-radius: 50%;
				background: #FFFFFF;
				color: #4C4C4C;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
			}

			.progress-line {
				height: 1px;
				width: 80px;
				background: #FFFFFF;
			}

			.blue {
				background: #07CC00;
				color: #FFFFFF;
			}
		}


		.app-form {
			width: calc(100vw - 48px);
			height: 80vh;
			border-radius: 24px 24px 0px 0px;
			background: #FFFFFF;
			padding: 24px;

			.form-title {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #292929;
				text-align: center;
			}

			.form-list {
				padding: 24px;

				.app-checkbox:last-child {
					margin-bottom: 0px;
				}
			}

			.form-item {
				display: flex;
				flex-direction: column;

				.item-title {
					padding-bottom: 4px;
					display: flex;
					align-items: center;

					.icon {
						width: 18px;
						height: 18px;
					}

					.text {
						padding-left: 8px;
						color: #4D4D4D;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
					}
				}

				.item-value {
					//height: 32px;
					border-radius: 8px;
					overflow: hidden;
					background: #F8F8F8;
					padding: 0px 24px;

					.value-input {
						width: 100%;
						height: 32px;
						line-height: 32px;
						background: #F8F8F8;
						border: 0px;
					}

					input {
						outline: none;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #333333;
					}

					input::placeholder {
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 500;
						color: #9FA0A2;
					}
				}

				.item-tips {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: red;
				}

				.item-upload {
					height: 66px;
					border-radius: 8px;
					background: #F8F8F8;
					overflow: hidden;

					.upload-style {
						display: flex;
						justify-content: center;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						height: 66px;

						.icon {
							width: 18px;
							height: 18px;
						}

						.u-text {
							//line-height: 0px;
							color: #C8C9CD;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
						}
					}

					.van-uploader__input-wrapper {
						width: 100%;
					}

					.van-uploader__upload {
						margin: 0;
						width: 100%;
						height: 66px;
						line-height: 66px;
					}

					.van-uploader__preview {
						margin: 0px;
						width: 100%;
						display: flex;
						justify-content: center;

						.van-uploader__preview-image {
							margin: 0px;
							width: 100%;
							height: 66px;
						}
					}
				}
			}
		}


		.form-buttons {
			width: calc(100vw - 48px);
			display: flex;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			bottom: 0px;
			padding: 18px 24px;
			background: #FFFFFF;
			z-index: 99;

			.button-border {
				width: 100px;
				height: 36px;
				line-height: 36px;
				background: #ffffff;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				color: #292929;
				text-align: center;
				border-radius: 24px;
				border: 1px solid #D9D9D9;
				box-sizing: border-box;
			}

			.button-blue {
				width: calc(100vw - 168px);
				height: 36px;
				line-height: 36px;
				background: #245BD9;
				color: #ffffff;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 600;
				text-align: center;
				border-radius: 24px;
			}
		}
	}
</style>
